<template>
    <div>
        <div>
            <Breadcrumb content="添加卡牌"/>
        </div>
        <div class="m-bg m-pd">
            <el-form v-model="form" label-width="100px" >
                <el-row>
                    <el-col :span="10" :offset="1">
                        <el-form-item label="卡名" required>
                            <el-input v-model="form.card_name"></el-input>
                        </el-form-item>
                        <el-form-item label="标签" required>
                            <el-input v-model="form.label"></el-input>
                            <div class="m-tips">字数控制在10个字符内</div>
                        </el-form-item>
                        <el-form-item label="补充包" required>
                            <el-select class="m-full" v-model="form.category">
                                <el-option v-for="(v,k) in products" :label="v.name" :key="k" :value="v.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="10">
                        <el-form-item label="卡图" required>
                            <AvatarUpload @uploadSuccess="handleBanner"></AvatarUpload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="10">
                        <el-form-item>
                            <el-button type="success" @click="add">立即保存</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    import AvatarUpload from "@/components/admin/avatar-upload"
    export default {
        name: "add",
        components:{Breadcrumb,AvatarUpload},
        data() {
            return {
                form: {
                    category:"",
                    img:"",
                    card_name:"",
                    label:"",
                },
                products:[],
            }
        },
        methods: {
            handleBanner(obj) {
                this.form.img = obj.imgUrl;
            },
            getProductList(){
                this.$axios.post("site/siteProducts/getSelectList")
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.products = res.data.data;
                            console.log(this.products)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                })
            },
            add(){

                // 校验
                for (let formKey in this.form) {
                    if (this.form[formKey] == ""){
                        return this.$message.error("提交参数不能为空");
                    }
                }

                this.$axios.post("site/siteDayCards/add",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.$message.success("保存成功");
                            setTimeout(()=>{
                                this.$router.push("dayCards")
                            },1000)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                })
            }
        },
        created() {
            this.getProductList()
        }
    }
</script>

<style scoped>

</style>
